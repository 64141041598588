import React from "react";
import TitleHeader from "../../components/TitleHeader";
import Layout from "../../components/layout"


const StomatologiaEstetyczna = () => {
  return (
    <Layout>
      <TitleHeader title="Stomatologia estetyczna"/>

      <div className="grid-two-column content">
        <h2 className="first-column">naturalne piękno</h2>
        <p className="second-column">
          Stomatologia estetyczna jest projektowaniem pięknego uśmiechu. To
          zabiegi przywracania naturalnej atrakcyjności zębom, które wraz z
          upływem czasu poddały się szkodliwemu działaniu czynników zewnętrznych
          i nierzadko deficytów żywieniowych. Dopasujemy zęby o odpowiednim
          kształcie, odcieniu. Możliwość stosowania implantów, licówek czy koron
          pełnoceramicznych daje nieograniczone pole działania stomatologii
          estetycznej.
        </p>
        <h2 className="first-column">metamorfoza uśmiechu</h2>
        <p>
          Dental Arts Studio Kraków posiada kompletne zaplecze umożliwiające
          kompleksowe zabiegi stomatologii estetycznej. Najnowocześniejsze i
          najbardziej zaawansowane technologicznie systemy wspierają
          doświadczenie i wiedzę naszego Personelu. Harmonia pięknych zębów to
          połączenie wiedzy, doświadczenia i materiałów stomatologicznych
          najwyższej jakości. wybielanie – profesjonalne i bezpieczne metody
          przywracania prawidłowego odcienia zębów oczyszczanie i piaskowanie –
          redukcja nalotów, osadów i przebarwień w bezpieczny sposób implanty –
          trwały i estetyczny zabieg, bezpieczeństwo dla szczęki, długa
          żywotność metody licówki – dla minimalnych i średnich korekt kształtu
          i koloru zębów korony pełnoceramiczne – możliwość naprawy bardzo
          zniszczonych zębów
        </p>
      </div>
    </Layout>
  );
};
export default StomatologiaEstetyczna;
